/** @jsx jsx */
import React from "react"
import { jsx, Flex } from "theme-ui"

type ProjectCardProps = {
  link: string
  title: string
  children: React.ReactNode
  bg: string,
  index: number
}

const gradients = [
  'linear-gradient(to right, #D4145A 0%, #FBB03B 100%)',
  'linear-gradient(to right, #662D8C 0%, #ED1E79 100%)',
  'linear-gradient(to right, #009245 0%, #FCEE21 100%)',
  'linear-gradient(to right, #D585FF 0%, #00FFEE 100%)'
]

const ProjectCard = ({ link, title, children, bg, index }: ProjectCardProps) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer noopener"
    sx={{
      width: `100%`,
      boxShadow: `lg`,
      position: `relative`,
      textDecoration: `none`,
      borderRadius: `lg`,
      px: 4,
      py: [4, 5],
      color: `white`,
      background: gradients[index] || `none`,
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    }}
  >
    <div
      sx={{
        textTransform: `uppercase`,
        letterSpacing: `wide`,
        pt: 4,
        color: `white`,
        fontSize: [4, 5],
        fontWeight: `medium`,
        textDecoration: 'none',
        lineHeight: 1,
      }}
    >
      {title}
    </div>
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '2rem',
        '@media (max-device-width: 650px)': {
          flexDirection: 'column-reverse'
        }
      }}
    >
      <div
        sx={{
          width: '60%',
          opacity: 0.75,
          textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`,
          '@media (max-device-width: 650px)': {
            width: `100%`
          }
        }}
      >{ children }</div>
      <div
        sx={{
          px: 0,
          py: 0,
          width: `33%`,
          paddingBottom: '33%',
          position: `relative`,
          borderRadius: `lg`,
          background: `url(${bg}) no-repeat`,
          backgroundSize: 'cover',
          '@media (max-device-width: 650px)': {
            width: `100%`,
            paddingBottom: '100%',
          }
        }}
      >
      </div>
    </Flex>
  </a>
)

export default ProjectCard
